/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import { FC } from 'react'
import { CollectionTemplateType, FluidImageObjectType } from '~/@types/models'
import ProductItem from '~/components/product-item'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'
import isolateFilters from '~/utils/filtering'
import { graphql, Link, useStaticQuery } from 'gatsby'

type Props = CollectionTemplateType

// const query = graphql`
//   query {
//     allShopifyCollection(
//       filter: {
//         handle: {
//           in: [
//             "contemporary-rugs"
//             "traditional-rugs"
//             "indoor-outdoor-rugs"
//             "performance"
//             "textured-rugs"
//             "antique-rugs"
//           ]
//         }
//       }
//       sort: { fields: metafields___rugcollectionlinkorder, order: ASC }
//     ) {
//       edges {
//         node {
//           id
//           handle
//           title
//           metafields {
//             collectionCustomImageUrl
//           }
//           image {
//             originalSrc
//             srcDesktopMedium
//           }
//           seo {
//             title
//             description
//           }
//         }
//       }
//     }
//   }
// `

// interface QueryResult {
//   allShopifyCollection: {
//     edges: {
//       node: Array<{
//         id: string
//         handle: string
//         title: string
//         metafields: {
//           collectionCustomImageUrl: string
//         }
//         image: {
//           originalSrc: string
//           srcDesktopMedium: string
//         }
//       }>
//     }
//   }
// }

const CollectionRugs = ({ pageContext: { data } }: Props) => {
  const filterData = isolateFilters(data)

  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      seoDescription={data.seo?.description || data.description}
      description={data.description}
      heading={data.metafields?.customHeading || data.title}
      ankleContent={data.metafields?.ankleContent}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      headerDescription={data.metafields?.headerDescription}
      catalog={data.catalog}
      handle={data.handle}
      featuredCollectionsList={data.featuredCollections}
    >
      <CollectionProducts
        data-template="collection-rugs"
        collectionHandle={data.handle}
        initialData={data.products}
        pagination={data.pagination}
        filters={filterData}
        showFilter={true}
        data={data}
        gridVariant="layout.template-rugs"
      />
    </CollectionLayout>
  )
}
export default CollectionRugs
